import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export async function toastMessage(title, icon = "CheckIcon", variant = "success") {
    await Vue.$toast({
        component: ToastificationContent,
        props: {
            title,
            icon,
            variant,
        },
    });
}
