import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const dateTimeFormats = {
  tr: {
    day: {
      day: "weekday",
    },
    month: {
      year: "numeric",
      month: "long",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric"
    },
    full: {
      minute: "2-digit",
      hour: "2-digit",
      day: "weekday",
      month: "narrow"
    }
  },
  en: {
    day: {
      day: "weekday",
    },
    month: {
      year: "numeric",
      month: "long",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric"
    },
    full: {
      minute: "2-digit",
      hour: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour12: false,
    }
  }
}


export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats
})
