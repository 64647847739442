
const state = {
    images: [],
    activeGroup: null
};

const getters = {
    activeImages: state => state.images.filter(i => i.group == state.activeGroup),
};

const mutations = {
    insertImage(state, image) {
        state.images.push(image)
    },
    removeImage(state, { id }) {
        state.images = state.images.filter(i => i.id != id);
    },
    selectGroup(state, { groupId }) {
        state.activeGroup = groupId
    }
}

const actions = {}


export default {
    state, getters, actions, mutations
}
