import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import i18n from './libs/i18n';
import './libs/gtag';
import './libs/metrika'
import App from './App.vue'

import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-material-design-icons/styles.css';

import './libs/axios'
import './libs/auth'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import { registerFilters } from './libs/filters'
registerFilters();
import Ripple from 'vue-ripple-directive'
Ripple.color = 'rgba(255, 255, 255, 0.35)';
Vue.directive('ripple', Ripple)


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6Le80vkeAAAAAKVor0pKPC-sKCWenDwQita6BoLL', loaderOptions: { useRecaptchaNet: true, autoHideBadge: true } })
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import { v4 as uuid } from 'uuid'

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

// import prototype factories
import {factories} from './factories'
factories()

Vue.directive(
  'lightbox', {
  bind: function (el, binding, vnode) {
    const store = vnode.context.$store;

    el.dataset.lightbox_id = uuid();
    const groupId = el.dataset.group ?? uuid()
    el.dataset.group = uuid();
    store.commit('insertImage', {
      src: el.src,
      original: el.dataset.original,
      group: groupId,
      id: el.dataset.lightbox_id
    });
    if (!el.dataset.ignore) {
      el.addEventListener('click', () => {
        store.commit('selectGroup', { groupId: groupId })
      })
    }


  },
  unbind: function (el, binding, vnode) {
    const store = vnode.context.$store;
    store.commit('removeImage', {
      id: el.dataset.lightbox_id
    });
  }
}
)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
