const { countries } = require("@/api/address.api");

const state = {
    countries: [],
    loading: false
};

const getters = {
    countries: country => state.countries,
    countryByIso: (state) => (iso2) => state.countries.find(c => c.iso2 == iso2)
};

const mutations = {
    setCountries(state, countries) {
        state.countries = countries
        state.loading = false;
    },
    setCountriesStatus(state, status) {
        state.loading = status
    }
}

const actions = {
    async fetchCountries({ commit }) {
        if (state.countries.length > 0 || state.countries.loading) return;
        commit('setCountriesStatus', true);
        const { data } = await countries();
        commit('setCountries', data);
    }
}

export default {
    state, getters, actions, mutations
}
