import Vue from 'vue'

// axios

import VueAxios from 'vue-axios'
import axios from 'axios'
import {toastMessage} from "@/libs/toast";
import i18n from '@/libs/i18n'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use((response) => {
  return response;
}, async (error) => {

  if (error.message.indexOf('Network Error') > -1) {
    toastMessage(
        i18n.tc('errors.network-error'),
        'AlertCircleIcon',
        'warning'
    )
    await Promise.resolve(error);
  }

  if (error.message.indexOf('timeout') > -1) {
    toastMessage(
        i18n.tc('errors.request-timeout'),
        'AlertCircleIcon',
        'warning'
    )
    await Promise.resolve(error);
  }

  if (error.isAxiosError) {
    // console.log(error);
  }

  return Promise.reject(error);
});

Vue.axios = axiosIns;
Vue.use(VueAxios, axiosIns)

export default axiosIns
