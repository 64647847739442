import Vue from 'vue';
import { eachDayOfInterval, format, formatISO, isAfter, parse, parseISO } from 'date-fns';

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export const FILTERS = {
    abbrNumber: (number) => {
        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    },
    period: (dates = []) => {
        if (dates.length == 0) return []
        const dateMin = dates.reduce((acc, curr) => acc == null || curr.date < acc ? curr.date : acc, null)
        let dateMax = dates.reduce((acc, curr) => acc == null || curr.date > acc ? curr.date : acc, null)

        if (isAfter(new Date(), parseISO(dateMax))) {
            dateMax = formatISO(new Date(), { representation: 'date' });
        }

        const data = eachDayOfInterval({ start: parseISO(dateMin), end: parseISO(dateMax) })
            .map(day => {
                const date = formatISO(day, { representation: 'date' });
                const count = dates.find(e => e.date == date)?.count;
                return {
                    date,
                    count: count ?? 0
                }
            })

        return data;
    },
    avatarText(value) {
        if (!value) return ''
        const nameArray = value.split(' ')
        return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    }
}



export const registerFilters = function () {
    Object.keys(FILTERS).forEach(key => {
        Vue.filter(key, FILTERS[key])
    })
}
