import Vue from 'vue';
import router from '@/router/index'
import VueYandexMetrica from 'vue-yandex-metrika';

Vue.use(
    VueYandexMetrica,
    {
        id: 87932259,
        router: router,
        env: process.env.NODE_ENV,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    }
)