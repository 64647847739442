const FACTORIES = {
    hashCode: () => {
        String.prototype.hashCode = function () {
            let hash = 0, i = 0, len = this.length;
            while (i < len) {
                hash = ((hash << 5) - hash + this.charCodeAt(i++)) << 0;
            }
            return hash + 2147483647 + 1;
        };
    }
}

export const factories = function () {
    Object.keys(FACTORIES).forEach(key => {
        FACTORIES[key]()
    })
}